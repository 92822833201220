import React from "react";
import Program from "../components/Program";

import Quote from "../components/Quote";
import Events from "../components/Events";
import Donation from "../components/Donation";
import Volunteer from "../components/Volunteer";
import Footer from "../components/Footer";

import "../styles.css";
import AboutPatriji from "../components/AboutPatriji";
import Meditation from "../components/Meditation";
import Spiritual from "../components/spiritual";
import SocialMedia from "../components/SocialMedia";
import Inperson from "../components/Inperson";
import WhyMeditation from "../components/WhyMeditation";
import WhyPyramids from "../components/WhyPyramids";
import HowToMeditate from "../components/HowToMeditate";
import InPersonProgramCard from "../components/InPersonProgramCard";

import {
  RecentProgramDetails,
  OngoingProgramDetails,
  InPersonProgramDetails,
  UpComingOnlinePrograms,
} from "../ProgramDetails";

function createInPersonProgram(program) {
  return (
    <InPersonProgramCard
      title={program.title}
      date={program.date}
      time={program.time}
      host={program.host}
      img={program.img}
      venue_location={program.venu_location}
      registration_link={program.registration_link}
      youtube_link={program.youtube_link}
    />
  );
}

function Event() {
  return (
    <div>
      {/* <Quote /> */}

      <div className="container"></div>
      <Events />
      {/* <WhyPyramids />
      <Donation />
      <Volunteer /> */}

      {/* <DonationComponent /> */}
    </div>
  );
}

export default Event;
