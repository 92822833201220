import React from "react";
import "../styles.css";
import flier from "../images/March2025/29Mar-10.jpeg";
import flier2 from "../images/March2025/PMMA3.jpeg";
import flier3 from "../images/March2025/Devi.jpeg";
import flier4 from "../images/April2025/27Apr.jpeg";

function Quote() {
  return (
    <div>
      {/* <div className="quote-main">
        <div>
          <img className="quote-flier" src={flier2} alt="Event Flier" />
        </div>

        <div className="event-details">
          <h2 className="event-title">
            🌟 PYRAMID MUSIC MEDITATION FEST - ATLANTA 🌟
          </h2>
          <div className="event-info">
            <p>
              <strong>Unite your soul with rhythm and stillness!</strong>
            </p>
          </div>

          <p className="event-highlight">
            📅 <strong>Date: Saturday, March 29th</strong>
          </p>
          <p className="event-highlight">
            ⏰{" "}
            <strong>Time: 9:30 AM to 5:00 PM [Experience it Full Day]</strong>
          </p>
          <p className="event-highlight">
            📍{" "}
            <strong>
              Venue: Celebrations Banquet, 2569 Peachtree Pkwy, #210, Cumming,
              GA 30041.
            </strong>
          </p>

          <p className="event-highlight">
            <strong>✨ Highlights:</strong>
          </p>
          <p className="event-highlight">
            ✅ Power Talk on Manifestation by Pari Patri
          </p>
          <p className="event-highlight">
            ✅ Immersive Music & Meditation Fusion
          </p>
          <p className="event-highlight">✅ Overnight Spiritual Journey</p>

          <p className="event-quote">
            🎶{" "}
            <em>"Music fulfills Meditation, and Meditation fulfills Music."</em>
            – Brahmarshi Patriji
          </p>

          <div className="button-container">
            <a
              href="https://bit.ly/apma-music-2025"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">🔗 Register Now</button>
            </a>
            <a
              href="https://bit.ly/donate-apma"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">💌 Donate to Support</button>
            </a>
          </div>

          <p className="event-highlight">
            📞 <strong>Contact:</strong>
          </p>
          <p className="event-highlight">
            📧 Email: <a href="mailto:info@atlpma.org">info@atlpma.org</a>
          </p>
          <p className="event-highlight">
            📱 WhatsApp:{" "}
            <a
              href="https://wa.me/17065918584"
              target="_blank"
              rel="noopener noreferrer"
            >
              +1 (706) 591-8584
            </a>
          </p>

          <p className="event-highlight">
            Join us for a transformative night of harmony, wisdom, and inner
            peace. Let’s awaken the divine within! 🌌
          </p>
        </div>
      </div> */}
      {/* <div className="quote-main">
        <div>
          <img className="quote-flier" src={flier3} alt="Event Flier" />
        </div>

        <div className="event-details">
          <h2 className="event-title">
            🔥 You’ve shed, you’ve struggled, you’ve surrendered… NOW IT’S TIME
            TO RECLAIM YOUR POWER! 🔥
          </h2>
          <div className="event-info">
            <p>
              <strong>
                On March 30, under the Super New Moon & Solar Eclipse, we’re
                stepping into the Mysteries of Isis—a high-frequency rebirthing
                ceremony where you’ll release the old, activate your divine
                feminine codes, and step into FULL GODDESS MODE. 💃✨
              </strong>
            </p>
          </div>

          <p className="event-highlight">
            👁️ <strong>Celestial Activations</strong>
          </p>
          <p className="event-highlight">
            🌑 <strong>New Moon Rebirth Ceremony</strong>
          </p>
          <p className="event-highlight">
            🌀 <strong>Isis Energy Transmissions</strong>
          </p>
          <p className="event-highlight">
            🔥 <strong>Meditation + Sacred Anointing</strong>
          </p>

          <p className="event-highlight">
            📅 <strong>Date: Sunday, March 30</strong>
          </p>
          <p className="event-highlight">
            ⏰ <strong>Time: 2:00 PM - 5:00 PM </strong>
          </p>
          <p className="event-highlight">
            📍{" "}
            <strong>
              Venue: Garden Of Gods (Meditation In Progress community) 6505
              Shiloh Rd, Alpharetta, GA (3rd Floor)
            </strong>
          </p>
          <p className="event-highlight">
            This is NOT just another “spiritual event.” This is a full-body,
            soul-shaking initiation into your highest self. 🦅💫
          </p>
          <p className="event-quote">
            ⚡ LIMITED SPOTS → RESERVE NOW:{" "}
            <a
              href="https://bit.ly/4bUfLC7"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://bit.ly/4bUfLC7
            </a>{" "}
            ⚡
          </p>

          <div className="button-container">
            <a
              href="https://bit.ly/4bUfLC7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">🔗 Register Now</button>
            </a>
            <a
              href="https://bit.ly/donate-apma"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">💌 Donate to Support</button>
            </a>
          </div>

          <p className="event-highlight">
            📞 <strong>Contact:</strong>
          </p>
          <p className="event-highlight">
            📧 Email: <a href="mailto:info@atlpma.org">info@atlpma.org</a>
          </p>
          <p className="event-highlight">
            📱 WhatsApp:{" "}
            <a
              href="https://wa.me/17065918584"
              target="_blank"
              rel="noopener noreferrer"
            >
              +1 (706) 591-8584
            </a>
          </p>

          <p className="event-highlight">
            Tag your soul sisters who NEED this energy shift. ⬇️💖
          </p>
        </div>
      </div> */}
      <div className="quote-main">
        <div>
          <img className="quote-flier" src={flier4} alt="Event Flier" />
        </div>

        <div className="event-details">
          <h2 className="event-title">
            🌟 Join Us for the April Meditation Fest! 🌟
          </h2>
          <p className="event-highlight">
            Experience deep meditation, wisdom, and healing at the Atlanta
            Pyramid Meditation Academy’s April Meditation Fest! ✨
          </p>

          <p className="event-info">
            Enjoy a peaceful evening filled with Pyramid Energies, Sound Bath
            Meditation, Eye Care Tips by Isha Teacher, and vegan food. 🌿🎶
          </p>

          <p className="event-highlight">
            📅 <strong>Date:</strong> April 27
          </p>
          <p className="event-highlight">
            ⏰ <strong>Time:</strong> 4:00 PM - 8:30 PM
          </p>
          <p className="event-highlight">
            📍 <strong>Location:</strong> Atlanta Area Council, Scouting America
            (1800 Circle 75 Pkwy, Atlanta, GA 30339)
          </p>

          <p className="event-highlight">
            ✨ Music Meditation | Wisdom Talks | Donation-based event ✨
          </p>

          <p className="event-info">
            📌 <strong>Registration is a must:</strong>
            <a
              href="https://bit.ly/apma-april-2025"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              bit.ly/apma-april-2025
            </a>
          </p>

          <p className="event-info">
            💛 <strong>Support our Project:</strong>
            <a
              href="https://bit.ly/donate-apma"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              bit.ly/donate-apma
            </a>
          </p>

          <p className="event-highlight">
            🙏 Please Donate wholeheartedly to support our efforts! Your
            generosity helps us enhance our arrangements and create a more
            enriching experience for all. 💖✨
          </p>

          <p className="event-highlight">
            🌎 <strong>Learn more about our work:</strong>
          </p>
          <p className="event-info">
            🔺 <strong>Our Events:</strong>{" "}
            <a
              href="https://atlpma.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              atlpma.org
            </a>
          </p>
          <p className="event-info">
            🌱 <strong>Our Vision:</strong>{" "}
            <a
              href="https://bit.ly/apma-vision"
              target="_blank"
              rel="noopener noreferrer"
            >
              bit.ly/apma-vision
            </a>
          </p>
          <p className="event-info">
            🧘‍♂️ <strong>Our Movement:</strong>{" "}
            <a
              href="https://pssmovement.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              pssmovement.org
            </a>
          </p>

          <div className="button-container">
            <a
              href="https://bit.ly/apma-april-2025"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">🔗 Register Now</button>
            </a>
            <a
              href="https://bit.ly/donate-apma"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">💌 Donate to Support</button>
            </a>
          </div>

          <p className="event-highlight">
            📞 <strong>Contact:</strong>
          </p>
          <p className="event-highlight">
            📧 Email: <a href="mailto:info@atlpma.org">info@atlpma.org</a>
          </p>
          <p className="event-highlight">
            📱 WhatsApp:{" "}
            <a
              href="https://wa.me/17065918584"
              target="_blank"
              rel="noopener noreferrer"
            >
              +1 (706) 591-8584
            </a>
          </p>
        </div>
      </div>

      <div className="quote-main">
        <div>
          <img className="quote-flier" src={flier} alt="Event Flier" />
        </div>
        <div className="event-details">
          <h2 className="event-title">🎈 Pyramid Music Meditation Fest 🪭</h2>
          <div className="event-info">
            <p>
              <strong>
                The “Pyramid Music Fest” in Atlanta was a grand success,
                creating a vibrant space for spiritual exploration and
                connection. The event featured soul-stirring live music that
                uplifted energies, a powerful manifestation workshop that
                inspired attendees to align with their desires, and engaging
                activities for kids to introduce them to mindfulness in a fun
                way.
              </strong>
            </p>
            <p>
              <strong>
                The pyramid project discussions sparked deep interest, and the
                visit to the pyramid land filled everyone with excitement and
                vision for future possibilities. The serene ambiance added to
                the high vibrations, fostering meaningful conversations, shared
                wisdom, and joyful moments. Many attendees expressed deep
                gratitude for the enriching experience, and the presence of Pari
                Patri elevated the collective energy of the gathering.
              </strong>
            </p>
          </div>

          <div className="button-container">
            <a
              href="https://bit.ly/atlanta-pyramid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">⚜️ Our Project</button>
            </a>
            <a
              href="https://bit.ly/donate-apma"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">🥇 Donate</button>
            </a>
            <a
              href="https://bit.ly/donate-atlanta-pyramid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="event-button">🏆 Donate (Global)</button>
            </a>
          </div>

          <p className="event-highlight">
            🌐 Our Events:{" "}
            <a
              href="https://atlpma.org/events"
              target="_blank"
              rel="noopener noreferrer"
            >
              atlpma.org/events
            </a>
          </p>
          <p className="event-highlight">
            ☯ Our Movement:{" "}
            <a
              href="https://pssmovement.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              pssmovement.org
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Quote;
