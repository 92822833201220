import React from "react";
import newsletter from "../images/icons/Newsletter.png";

function Subscribe() {
  return (
    <div>
      <a href="https://bit.ly/subscribe2apma ">
        <img className="subscribe" src={newsletter} alt="newsletter-img" />
      </a>
    </div>
  );
}

export default Subscribe;
