import React from "react";
import Program from "../components/Program";

import Quote from "../components/Quote";
import Events from "../components/Events";
import Donation from "../components/Donation";
import Volunteer from "../components/Volunteer";
import Footer from "../components/Footer";

import "../styles.css";
import AboutPatriji from "../components/AboutPatriji";
import Meditation from "../components/Meditation";
import Spiritual from "../components/spiritual";
import SocialMedia from "../components/SocialMedia";
import Inperson from "../components/Inperson";
import WhyMeditation from "../components/WhyMeditation";
import WhyPyramids from "../components/WhyPyramids";
import HowToMeditate from "../components/HowToMeditate";
import Anapanasati from "../components/Anapanasati";
import Procedure from "../components/Procedure";
import WhatHappens from "../components/WhatHappens";
import PyramidMeditation from "../components/PyramidMeditation";
import PyramidProject from "../components/PyramidProject";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <div>
        <Helmet>
          <title>Atlanta Pyramid Meditation Academy</title>
          <meta
            property="og:title"
            content="Atlanta Pyramid Meditation Academy"
          />
          <meta
            property="og:description"
            content="Join us for meditation events, workshops, and more at the Atlanta Pyramid Meditation Academy. Discover peace and mindfulness today!"
          />
          <meta
            property="og:image"
            content="https://atlpma.org/images/Dec29.jpeg"
          />
          <meta property="og:url" content="https://atlpma.org" />
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content="Join us for meditation events, workshops, and more at the Atlanta Pyramid Meditation Academy. Discover peace and mindfulness today!"
          />
          <meta
            name="keywords"
            content="meditation, Atlanta, Pyramid Meditation, mindfulness, workshops, events"
          />
          <meta name="author" content="Atlanta Pyramid Meditation Academy" />
          <meta name="robots" content="index, follow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="canonical" href="https://atlpma.org" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Atlanta Pyramid Meditation Academy"
          />
          <meta
            name="twitter:description"
            content="Join us for meditation events, workshops, and more at the Atlanta Pyramid Meditation Academy. Discover peace and mindfulness today!"
          />
          <meta
            name="twitter:image"
            content="https://atlpma.org/images/Dec29.jpeg"
          />
          <meta name="twitter:url" content="https://atlpma.org" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Organization",
              name: "Atlanta Pyramid Meditation Academy",
              url: "https://atlpma.org",
              logo: "https://atlpma.org/images/Dec29.jpeg",
              sameAs: [
                "https://www.facebook.com/atlpma",
                "https://twitter.com/atlpma",
                "https://www.instagram.com/atlpma",
              ],
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+1-800-555-5555",
                contactType: "Customer Service",
              },
            })}
          </script>
        </Helmet>
      </div>
      <PyramidProject />
      <Quote />
      <div className="container">
        <div className="left-container">
          <div className="mobile-version outer-box">
            <AboutPatriji />
            <Meditation />
            <Spiritual />
            {/* <SocialMedia /> */}
          </div>
          {/* <Program /> */}
          <Anapanasati />
          <Procedure />
          <WhatHappens />
          <PyramidMeditation />
          <WhyMeditation />
          <HowToMeditate />
          {/* <Inperson /> */}
          {/* <Events /> */}
          {/* <WhyPyramids /> */}
          {/* <Donation />
          <Volunteer /> */}
        </div>
        <div className="right-container">
          <AboutPatriji />
          <Meditation />
          <Spiritual />
          {/* <SocialMedia /> */}
        </div>
      </div>
      {/* <Events /> */}
      <WhyPyramids />
      <Donation />
      <Volunteer />
      {/* <DonationComponent /> */}
    </div>
  );
}

export default Home;
