import React from "react";

import Donation from "../components/Donation";
import Volunteer from "../components/Volunteer";
import Footer from "../components/Footer";

import "../styles.css";
import altZelle from "../images/atlZelle1.jpeg";
import altZeffy from "../images/icons/zeffy.png";

function DonationPage() {
  return (
    <div>
      <div className="donate-details">
        <p className="donate-details-heading">Donate</p>
        <div>
          <span className="side-value">
            <div>
              <span className="side-heading">Zeffy: </span>
              <a href="https://bit.ly/donate-apma">
                <span className="side-value"> bit.ly/donate-apma</span>
              </a>
            </div>
            <div>
              <p>
                <span>Donate from Rest of the World </span>
                <span className="side-heading">Zeffy: </span>
                <a href="https://bit.ly/donate-atlanta-pyramid">
                  <span className="side-value">
                    {" "}
                    bit.ly/donate-atlanta-pyramid
                  </span>
                </a>
              </p>
            </div>
            <form
              action="https://www.zeffy.com/embed/donation-form/donate-to-make-a-difference-4600?modal=true"
              method="post"
              target="_top"
            >
              <input
                type="image"
                src={altZeffy}
                className="atlZelle"
                alt="Donate with Zeffy button"
                border="0"
                name="submit"
                title="Donation form powered by Zeffy"
                style={{
                  border: "2px solid gray",
                  borderRadius: "5px",
                  backgroundColor: "#f4900c",
                  boxShadow: "5px 5px 15px 5px rgba(245, 185, 8, 0.5)",
                }}
              />
            </form>
          </span>
        </div>
      </div>
    </div>
  );
}

export default DonationPage;
