const OngoingProgramDetails = [
  {
    id: 1,
    title: "Buddha Pournami Meditation",
    date: "April 3rd to 12th May",
    time: "4:00 AM - 7:00 AM EST",
    img: require("./images/March2025/OnlineMeditation.jpeg"),
    zoom_link: "",
    zoom_meeting_id: "816 3687 0526",
    zoom_passcode: "9999",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
];

const UpComingOnlinePrograms = [
  // {
  //   id: 1,
  //   title: "Buddha Pournami Meditation",
  //   date: "April 3rd to 12th May",
  //   time: "4:00 AM - 7:00 AM EST",
  //   img: require("./images/March2025/OnlineMeditation.jpeg"),
  //   zoom_link: "",
  //   zoom_meeting_id: "816 3687 0526",
  //   zoom_passcode: "9999",
  //   registration_link: "",
  //   youtube_link: "https://www.youtube.com/@AtlPMA",
  // },
];
const InPersonProgramDetails = [
  {
    id: 4,
    title:
      "🌟 Join us to experience the Sound bath with crystal singing bowls🌟",
    date: "Friday, April 4th",
    time: "8:30 PM to 9:30 PM EST",
    host: "Lakshmi Devi & Deva",
    img: require("./images/April2025/4Apr.jpeg"),
    venu_location: " 1710 Beckham Street, Cumming, GA 30041 ",
    registration_link: "",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
  {
    id: 3,
    title: "🌟 Garden of Gods-Mediation & Gathering 🌟",
    date: "Saturday, April 5th",
    time: "9:00 AM to 11:00 AM EST",
    host: "Sheetal & Chandra",
    img: require("./images/April2025/5Apr.jpeg"),
    venu_location: " 6505 Shiloh Rd, Alpharetta, GA(3rd Floor) ",
    registration_link: "",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
  {
    id: 2,
    title: "🌟 Garden of Gods-Mediation & Gathering 🌟",
    date: "Sunday, April 6th",
    time: "8:00 AM to 9:00 AM EST",
    host: "Swetha & Srujan",
    img: require("./images/April2025/6Apr.jpeg"),
    venu_location: " 4070,Winterberry Rd, Cumming - 30040 ",
    registration_link: "",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
  {
    id: 5,
    title: "🌟 World Health Day Meditation 🌟",
    date: "Monday, April 7th",
    time: "8:30 PM to 10:00 AM EST",
    host: "Swetha & Srujan",
    img: require("./images/April2025/7Apr.jpeg"),
    venu_location: " 816, Langley Path, Johnscreek GA 30024 ",
    registration_link: "",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
  {
    id: 1,
    title: "🌟 Join Us for the April Meditation Fest! 🌟",
    date: "Sunday, April 27th",
    time: "4:00 PM - 8:30 PM EST",
    host: "APMA Team",
    img: require("./images/April2025/27Apr.jpeg"),
    venu_location:
      "Atlanta Area Council, Scouting America (1800 Circle 75 Pkwy, Atlanta, GA 30339) ",
    registration_link: "https://bit.ly/apma-april-2025",
    youtube_link: "https://www.youtube.com/@AtlPMA",
  },
];

const RecentProgramDetails = [
  // {
  //   id: 1,
  //   title: "Heal Your Life with Mirror Work workshop",
  //   date: "EVERY MORNING",
  //   time: "4.30 AM CST | 5.30 AM EST",
  //   img: require("./images/MirrorWS.jpeg"),
  //   zoom_link:
  //     "https://us02web.zoom.us/j/86865160629?pwd=uaZsSRppS410nTdiXNk91oOeqKZ2Dv.1",
  //   zoom_meeting_id: "868 6516 0629",
  //   zoom_passcode: "9999",
  //   youtube_link: "https://www.youtube.com/live/NhGB2aoVeu8",
  // },
  // {
  //   id: 8,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: "June 22nd",
  //   time: "9AM to 4PM EST",
  //   img: require("./images/Virgina_DMC.jpeg"),
  //   venu_location:
  //     "Eagle Ridge Middle School, 42901 Waxpool Rd, Ashburn, VA, 20148",
  //   registration_link: "http://tiny.cc/9wqxxz",
  //   youtube_link: "https://www.youtube.com/live/1vkvQ65bnic",
  // },
  // {
  //   id: 7,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: "May 25th",
  //   time: "9AM to 4PM EST",
  //   img: require("./images/PSSM_MINI_TEXAS_2.jpeg"),
  //   venu_location:
  //     "Shridi Sai Community Hall, 2509 W New Hope Dr, Cedar Park, TX 78613",
  //   registration_link: "https://guestlist.co/u/pssmaustin",
  //   youtube_link: "https://www.youtube.com/@PMCUSA",
  // },
  // {
  //   id: 6,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: "April 27th",
  //   time: "10 AM to 4PM EST",
  //   img: require("./images/MINI_DMC_OHIO_2.jpeg"),
  //   venu_location: "5071 , Wallings Rd, North Royalton, OH 44133",
  //   registration_link: "https://tinyurl.com/PSSM-Meditation-cle-OH",
  //   youtube_link: "https://www.youtube.com/watch?v=k8omuN1p-Pc",
  // },
  // {
  //   id: 5,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: "March 23rd",
  //   time: "9AM to 4PM EST",
  //   img: require("./images/PSSM_MINI_GA2.jpeg"),
  //   venu_location: "Banjara Banquets, 1656 Buford Hwy, Cumming, GA 30041",
  //   registration_link: "http://tinyurl.com/pssm-atl",
  //   youtube_link: "https://www.youtube.com/watch?v=99BMHJOCbCY",
  // },
  // {
  //   id: 4,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: " February 24th",
  //   time: "9AM to 4PM EST",
  //   img: require("./images/NJ_MMST.jpeg"),
  //   venu_location: "Ved Mandir, 1 Ved Mandir Dr, Milltown, NJ 08850",
  //   registration_link:
  //     "https://docs.google.com/forms/d/e/1FAIpQLScjRc7GlGICYRzGbxRBwZo3QXKdcpXOvpWK6RiELMh6UxFdgA/viewform",
  //   youtube_link: "https://www.youtube.com/watch?v=A6AruSLMYT4",
  // },
  // {
  //   id: 3,
  //   title: "PSSM MEDITATION SUMMIT",
  //   date: "27th January 2024 - Saturday",
  //   time: "10 AM to 4 PM CST",
  //   img: require("./images/Monthly_MMS_Jan.jpg"),
  //   // venu_location:
  //   //   "Nolensville First United Methodist Church, 7316 Nolensville Rd, Nolensville TN 37135",
  //   // registration_link:
  //   //   "https://docs.google.com/forms/d/e/1FAIpQLSdrSA5uFz5qQ6bBwT-_eAyIBrL2O7SZUTX6-xZAhM4l7RXzGg/viewform",
  //   youtube_link: "https://www.youtube.com/watch?v=XyKvcqvVlCM",
  // },
  // {
  //   id: 2,
  //   title: "Harmony Within - Workshop in Atlanta",
  //   date: "26th January 2024 - Friday",
  //   time: "6.30 PM to 8 PM EST",
  //   img: require("./images/SPT_Chandra_Sir.jpeg"),
  //   // venu_location:
  //   //   "Nine East Building (Behind Kroger) 1740 Grassland Parkway, Block 400 Alpharetta, Georgia 30004",
  //   // registration_link: "https://forms.gle/D7NhKtRrjX8eKxfG6",
  //   youtube_link: "https://www.youtube.com/watch?v=kUTAzZCdFH0",
  // },
  // {
  //   id: 1,
  //   title: "CHANGE WHAT YOU EAT CHANGE THE WORLD",
  //   date: "NOV 19th 2023 ",
  //   time: "8.30 PM to 10 PM EST",
  //   img: require("./images/gaurav.jpeg"),
  //   // zoom_link:
  //   //   "https://us02web.zoom.us/j/83299568390?pwd=SUJOZWsyUGE3ei9naEUvMTJUdHEwdz09",
  //   // zoom_meeting_id: "832 9956 8390",
  //   // zoom_passcode: "801942",
  //   youtube_link: "https://www.youtube.com/watch?v=8QSMI2Oy9Q4&t=3s",
  // },
];

export {
  RecentProgramDetails,
  OngoingProgramDetails,
  InPersonProgramDetails,
  UpComingOnlinePrograms,
};
