import React from "react";
import "../PyramidProject.css"; // Import your CSS file for styling

function PyramidProject() {
  return (
    <div className="pyramid-project">
      <div className="pyramid-project-title">
        <span>Our Upcoming Permanent Meditation Center for Atlanta.</span>
      </div>
      <div className="pyramid-paragraph">
        <p>
          Seeking serenity in a busy world? Let's unite to create a sacred space
          for meditation, self-discovery, and bliss! Introducing the Atlanta
          Pyramid, coming soon! Join the movement and stay tuned for updates!.
        </p>
        <p className="more-info">
          <a href="https://bit.ly/atlanta-pyramid" target="_blank">
            {" "}
            More info
          </a>
        </p>
      </div>

      <div className="pyramid-video-wrapper">
        <iframe
          className="pyramid-video-responsive"
          src="https://www.youtube.com/embed/LfjH6AdogTM"
          allowFullScreen
          allow=""
        ></iframe>
      </div>
      <div className="donate-now ">
        <div className="donate-us">
          <a
            href="https://bit.ly/donate-apma"
            target="_blank"
            className="nav-donate-btn "
          >
            Donate Now
          </a>
        </div>
        <div className="donate-rest-of-world">
          <a
            href="https://bit.ly/donate-atlanta-pyramid"
            target="_blank"
            className="nav-donate-btn "
          >
            Donate from Rest of the World
          </a>
        </div>
      </div>
    </div>
  );
}

export default PyramidProject;
