import React from "react";
import Program from "../components/Program";

import Quote from "../components/Quote";
import Events from "../components/Events.jsx";
import Donation from "../components/Donation";
import Volunteer from "../components/Volunteer";
import Footer from "../components/Footer";

import "../styles.css";
import TestimonialsCard from "../components/TestimonialsCard.jsx";
import TestimonialsDetails from "../TestimonialsDetails";

function createTestimonialsCard(testimonials) {
  return (
    <TestimonialsCard
      image={testimonials.image}
      title={testimonials.title}
      name={testimonials.name}
      text={testimonials.text}
    />
  );
}

function Testimonials() {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Testimonials</h1>
      <div className="testimonials-container">
        {TestimonialsDetails.map(createTestimonialsCard)}
      </div>
    </div>
  );
}

export default Testimonials;
