import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "../styles.css";
import "../media-queries.css";
import apma from "../images/apmabanner.png";

import pssmlogo from "../images/icons/APMALogo.png";

const isMobile = window.matchMedia("(max-width: 479px)").matches;

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="nav">
        <div className="logo">
          <a href="/">
            {" "}
            <img className="pssm-logo" src={pssmlogo} alt="pssm-logo"></img>
          </a>
        </div>

        <div className="nav-container">
          <div className="donate-btn-div top-nav">
            <a href="/donate" className="nav-donate-btn ">
              Donate
            </a>
          </div>
          <div className="donate-btn-div top-nav">
            <a href="/subscribe" className="nav-donate-btn ">
              Subscribe
            </a>
          </div>
          <div>
            {isMobile ? (
              <>
                <h1 className="nav-heading">Atlanta</h1>
                <h1 className="nav-heading">Pyramid Meditation</h1>
                <h1 className="nav-heading">Academy</h1>
                <h1 className="nav-heading">(APMA)</h1>
                <h2 className="nav-subheading">
                  A 501(c)(3) Nonprofit Dedicated to Meditation and Mindfulness
                </h2>
              </>
            ) : (
              <>
                <h1 className="nav-heading">
                  Atlanta Pyramid Meditation Academy (APMA)
                </h1>
                <h2 className="nav-subheading">
                  A 501(c)(3) Nonprofit Dedicated to Meditation and Mindfulness
                </h2>
              </>
            )}
          </div>

          <div className={`nav-links ${isOpen ? "open" : ""}`}>
            <a
              href="/home"
              className={window.location.pathname === "/home" ? "active" : ""}
            >
              HOME
            </a>
            <a
              href="/events"
              className={window.location.pathname === "/events" ? "active" : ""}
            >
              RECENT EVENTS
            </a>
            <a
              href="/programs"
              className={
                window.location.pathname === "/programs" ? "active" : ""
              }
            >
              CURRENT PROGRAMS
            </a>
            <a
              href="https://www.pssmovement.org/patriji-recommended-books/"
              target="_blank"
            >
              BOOKS
            </a>
            <a
              href="/testimonials"
              className={
                window.location.pathname === "/testimonials" ? "active" : ""
              }
            >
              TESTIMONIALS
            </a>
            <a
              href="/local-masters"
              className={
                window.location.pathname === "/local-masters" ? "active" : ""
              }
            >
              CONTACT US
            </a>
          </div>
        </div>

        <a href="#" className="nav-toggle" onClick={handleToggle}>
          <FontAwesomeIcon className="nav-icon" icon={faBars} />
        </a>
      </nav>
    </div>
  );
}
export default Navbar;
