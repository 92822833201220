import React from "react";
import "../styles.css";

const TestimonialsCard = (props) => {
  return (
    <div className="testimonials-card">
      <div className="testimonials-head">
        <h2 className="testimonials-name">{props.name}</h2>
        <img
          src={props.image}
          alt={`${props.name}'s avatar`}
          className="testimonials-image"
        />
      </div>

      <div className="testimonials-details">
        <h3 className="testimonials-title">
          <span>❝ {props.title} ❞</span>
        </h3>
        <p className="testimonials-text">{props.text}</p>
      </div>
    </div>
  );
};

export default TestimonialsCard;
