import React from "react";
import OnlineProgram from "../components/OnlinePrograms";

import "../styles.css";

function OnlineProgramPage() {
  return (
    <div>
      {/* <Quote /> */}
      <div className="container">
        <div className="left-container">
          <div className="mobile-version outer-box">
          </div>
          <OnlineProgram />
        </div>
        <div className="right-container">
        </div>
      </div>
    </div>
  );
}

export default OnlineProgramPage;
