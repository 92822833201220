const ContactDetails = [
  {
    id: 1,
    name: "Srikanth Garla",
    phone: "+1 (603) 217-4004",
    city: "Suwanee",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 2,
    name: "Sheetal Pawar",
    phone: "+1 (636) 489-8585",
    city: "Suwanee",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 3,
    name: "Arun Kumar Alladurgam",
    phone: "+1 (251) 509-5151",
    city: "Cumming",
    state: "Georgia",
    image: require("./ContactPhotos/ArunPP.JPG"),
  },
  {
    id: 4,
    name: "Praveen Pippiri",
    phone: "+1 (404) 947-0924",
    city: "Cumming",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 5,
    name: "Pushpa Pochem",
    phone: "+1 (617) 840-8895",
    city: "Alpharetta",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 6,
    name: "Sirisha Pamidi",
    phone: "+1 (404) 663-1879",
    city: "johnscreek",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 7,
    name: "Lakshmi Devi",
    phone: "+1 (704) 724-9260",
    city: "Cumming",
    state: "Georgia",
    image: require("./ContactPhotos/user.png"),
  },
  {
    id: 8,
    name: "Divya Makam",
    phone: "+1 (404) 380 2251",
    city: "Sandy springs",
    state: "Georgia",
    image: require("./images/Profile/Divya.jpeg"),
  },
];

export default ContactDetails;
