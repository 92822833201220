import React from "react";

import LocalEventCard from "./LocalEventCard";
import FlyerPhotos from "./FlyerPhotos";
import {
  LocalEventDetails,
  USA_EventDetails,
  eventData,
} from "../EventDetails";
// import LocalEventDetails from "../EventDetails";

function createEvent(event) {
  return (
    <LocalEventCard
      title={event.title}
      img={event.img}
      date={event.date}
      description={event.description}
      count={event.count}
      host={event.host}
      location={event.location}
    />
  );
}

function createEventPhotos(eventData) {
  return <FlyerPhotos eventData={eventData} />;
}

function Event() {
  return (
    <div className="outer-box">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: "10px",
          paddingTop: "2%",
        }}
      >
        <div>
          <a href="/events/2024" className="nav-donate-btn ">
            2024
          </a>
        </div>
        <div>
          <a href="/events" className="nav-donate-btn ">
            2025
          </a>
        </div>
      </div>
      <div className="event-title">
        <h4 style={{ textAlign: "center" }}>EVENTS</h4>
        {/* <div className="Location">
          <label>Local Events</label>
          <select className="city">
            <option value="Atlanta">Atlanta</option>
            <option value="New Jersey">New Jersey</option>
            <option value="Dallas">Dallas</option>
          </select>
        </div> */}
      </div>
      <div className="local-events">
        {/* {LocalEventDetails.map(createEvent)} */}
        {eventData.map(createEventPhotos)}
        {/* <FlyerPhotos eventData={eventData} /> */}
        {/* <LocalEventCard /> */}
      </div>
      {/* <hr /> */}
      {/* <div className="event-title">
        <label>USA Events</label>
      </div>
      <div className="local-events">{USA_EventDetails.map(createEvent)}</div> */}
    </div>
  );
}

export default Event;
